<template>
  <div class="ahieve-page">
    <!--achieve-one start-->
    <div class="achieve-one" v-if="userInfo.position != 1">
      <!--one-box start-->
      <div class="one-box">
        <div class="one-con">
          <div class="achieve-tit">今日目标</div>
          <ul class="flex-ul">
            <li>
              <router-link to="/addTourist?type=1">
                <div class="t"><span class="color_red">{{ today.clientCount }}</span> /{{ today.new_clients_num }}</div>
                <div class="c">新增客户</div>
              </router-link>
            </li>
            <li>
              <router-link to="/addHouse?type=1">
                <div class="t"><span class="color_red">{{ today.houseCount }}</span> /{{ today.new_house_num }}</div>
                <div class="c">新增房源</div>
              </router-link>
            </li>
            <li>
              <router-link to="/achievementT?type=1">
                <div class="t"><span class="color_red">{{ today.daiLookCount }}</span> /{{ today.new_take_look_num }}</div>
                <div class="c">新增带看</div>
              </router-link>
            </li>

            <li>
              <router-link to="/achievementFollow?type=1">
                <div class="t"><span class="color_red">{{ today.genLookCount }}</span> /{{ today.new_follow_num }}</div>
                <div class="c">新增跟进</div>
              </router-link>
            </li>
            <li>
              <router-link to="/perParty?type=2&types=1">
                <div class="t"><span class="color_red">{{ today.finish_key_num }}</span> /{{ today.new_key_num }}</div>
                <div class="c">新增钥匙方</div>
              </router-link>
            </li>
            <li>
              <router-link to="/perParty?type=1&types=1">
                <div class="t"><span class="color_red">{{ today.finish_msg_num }}</span> /{{ today.new_msg_num }}</div>
                <div class="c">新增信息方</div>
              </router-link>
            </li>
            <li>
              <router-link to="/perParty?type=3&types=1">
                <div class="t"><span class="color_red">{{ today.finish_wt_num }}</span> /{{ today.new_about_look_num }}</div>
                <div class="c">新增委托方</div>
              </router-link>
            </li>
            <li>
              <router-link to="/perParty?type=4&types=1">
                <div class="t"><span class="color_red">{{ today.finish_img_num }}</span> /{{ today.new_img_num }}</div>
                <div class="c">新增图片方</div>
              </router-link>
            </li>
            <li>
              <router-link to="/perParty?type=5&types=1">
                <div class="t"><span class="color_red">{{ today.finish_wh_num }}</span> /{{ today.new_wh_num }}</div>
                <div class="c">新增维护方</div>
              </router-link>
            </li>
          </ul>
          <div class="achieve-tip" :class="{red:today.z_status==1}">
            
            <img v-if="today.z_status==1" class="icon" src="../../assets/image/personage/icon8.png" />
            <img v-else class="icon" src="../../assets/image/personage/icon7.png" />
            <div class="c">{{ today.z_status==1 ? '恭喜您，本日目标已完成~' : '不要气馁，再接再厉加油吧~' }}</div>
          </div>
        </div>
      </div>
      <!--one-box end-->
      <!--one-box start-->
      <div class="one-box">
        <div class="one-con">
          <div class="achieve-tit">本月目标</div>
          <ul class="flex-ul">
            <li>
              <router-link to="/addTourist?type=2">
                <div class="t"><span class="color_red">{{ month.clientCount }}</span> /{{  month.new_clients_num}}</div>
                <div class="c">新增客户</div>
              </router-link>
            </li>
            <li>
              <router-link to="/addHouse?type=2">
                <div class="t"><span class="color_red">{{ month.houseCount }}</span> /{{  month.new_house_num}}</div>
                <div class="c">新增房源</div>
              </router-link>
            </li>
            <li>
              <router-link to="/achievementT?type=2">
                <div class="t"><span class="color_red">{{ month.daiLookCount }}</span> /{{ month.new_take_look_num }}</div>
                <div class="c">新增带看</div>
              </router-link>
            </li>

            <li>
              <router-link to="/achievementFollow?type=2">
                <div class="t"><span class="color_red">{{ month.genLookCount }}</span> /{{  month.new_follow_num}}</div>
                <div class="c">新增跟进</div>
              </router-link>
            </li>
            <li>
              <router-link to="/perParty?type=2&types=2">
                <div class="t"><span class="color_red">{{ month.finish_key_num }}</span> /{{  month.new_key_num}}</div>
                <div class="c">新增钥匙方</div>
              </router-link>
            </li>
            <li>
              <router-link to="/perParty?type=1&types=2">
                <div class="t"><span class="color_red">{{ month.finish_msg_num }}</span> /{{  month.new_msg_num}}</div>
                <div class="c">新增信息方</div>

              </router-link>
            </li>
            <li>
              <router-link to="/perParty?type=3&types=2">
                <div class="t"><span class="color_red">{{ month.finish_wt_num }}</span> /{{  month.new_wt_num}}</div>
                <div class="c">新增委托方</div>
              </router-link>
            </li>
            <li>
              <router-link to="/perParty?type=4&types=2">
                <div class="t"><span class="color_red">{{ month.finish_img_num }}</span> /{{  month.new_img_num}}</div>
                <div class="c">新增图片方</div>
              </router-link>
            </li>
            <li>
              <router-link to="/perParty?type=5&types=2">
                <div class="t"><span class="color_red">{{ month.finish_wh_num }}</span> /{{  month.new_wh_num}}</div>
                <div class="c">新增维护方</div>

              </router-link>
            </li>
          </ul>
          
          <div class="achieve-tip" :class="{red:month.z_status==1}">
            <img v-if='month.z_status==1' class="icon" src="../../assets/image/personage/icon8.png" />
            <img v-else class="icon" src="../../assets/image/personage/icon7.png" />
            <div class="c">{{ month.z_status==1 ? '恭喜您，本月目标已完成~' : '不要气馁，再接再厉加油吧~' }}</div>
          </div>
        </div>
      </div>
      <!--one-box end-->
    </div>
    <!--achieve-one end-->
    <!--achieve-two start-->
    <div class="achieve-two">
      <div class="flex flex-between">
        <div class="achieve-tit">{{ getPosition(userInfo.position) }}</div>
        <div class="tit-r">
          <el-button v-if="userInfo.position == 1 || userInfo.position == 2" class="achieve-btn m-r-10" @click="$refs.juris.open(userInfo.position)">权限设置</el-button>
          <el-button v-if="userInfo.position != 4" class="achieve-btn blue" @click="$refs.fpop.open()">制定目标</el-button>
        </div>
      </div>
      <!--two-item start-->
      <div class="two-item" v-for="(item, index) in list" :key="index">
        <div class="two-l">
          <img class="img" :src="item.image" />
          <div class="two-txt">
            <div class="t">
              <span class="name m-r-20">{{ item.name }}</span>
              <span class="tel m-r-20">{{ item.mobile }}</span>
              <span class="address color_blue">#{{ item.city }}</span>
            </div>
            <div class="c">工号：{{ item.job_number }}</div>
          </div>
        </div>
        <div class="two-r">
          <div class="r-hd">
            <router-link class="see-a color_orange m-r-20" :to='`/sourceCustomer?uid=${item.id}`'>
              <img class="icon" src="../../assets/image/personage/icon17.png" />
              <span class="t">查看客源</span>
            </router-link>
            <div class="see-a color_orange m-r-20" @click="$refs.apop.open(item.id)">
              <img class="icon" src="../../assets/image/personage/icon11.png" />
              <span class="t">查看业绩</span>
            </div>
            <div @click="lookJunior(item)" class="see-a color_blue">
              <img class="icon" src="../../assets/image/personage/icon12.png" />
              <span class="t">查看下级</span>
            </div>
          </div>
          <div class="r-bd">
            <span class="name m-r-10">业绩进度：</span>
            <div class="gress">
              <div class="gress-s" :style="`width: ${item.finish_rate*100}%`"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="paging" v-if="list.length">
          <el-pagination
            background
            @current-change="changePage"
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      <!--two-item end-->
    </div>
    <!--achieve-two end-->
    <!-- 制定目标弹框 S -->
    <formu-pop ref="fpop"></formu-pop>
    <!-- 制定目标弹框 E -->
    <!-- 查看业绩弹框 S -->
    <achieve-pop ref="apop"></achieve-pop>
    <!-- 查看业绩弹框 E -->
    <!-- 权限设置弹框 S -->
    <jurisdiction-set ref="juris"></jurisdiction-set>
    <!-- 权限设置弹框 E -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormuPop from "../../components/FormuPop";
import AchievePop from "../../components/AchievePop";
import JurisdictionSet from "../../components/JurisdictionSet";
// import 'swiper/css/swiper.css'
export default {
  components: { FormuPop, AchievePop, JurisdictionSet },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token", "city"]),
  },
  name: "Achieve",
  data () {
    return {
      page: 1,
      total: 0,//
      list: [],//
      today: {},//今日目标
      month: {},//本月目标
    }
  },
  methods: {
    lookJunior (item) {
      switch (this.userInfo.position) {
        case 1:
          this.$router.push({ path: '/personage/achieveS', query: { id: item.id, dian_id: item.dian_id } })
          break;
        case 2:
          this.$router.push({ path: '/personage/achieveA', query: { id: item.id } })
          break;
        case 3:
          this.$router.push({ path: '/personage/achieveA', query: { id: item.id } })
          break;
        default:
          break;
      }
    },
    changePage (e) {
      this.page = e
      this.getareaList()
    },
    //获取头部信息
    getMessage () {
      this.$axios.getTargetInfo().then(res => {
        this.today = res.data
      })
      this.$axios.getTargetMonthInfo().then(res => {
        this.month = res.data
      })
    },
    //获取列表
    getareaList () {
      this.$axios.areaList({
        page: this.page
      }).then(res => {
        this.list = res.data.data
        this.total = res.data.total
      })
    }
  },
  created () {
    this.getareaList()
    this.getMessage()
  }
};
</script>

<style scoped lang="less">
.achieve-one {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  .one-box {
    width: 480px;
    background: #fff;
  }
  .one-con {
    padding: 20px 30px;
    .flex-ul {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0px 10px 0px;
      li {
        width: 33.333%;
        text-align: center;
        margin: 15px 0px;
      }
      .t {
        font-size: 20px;
      }
      .color_red {
        color: #ff2121;
      }
      .c {
        font-size: 16px;
        color: #666;
        margin-top: 5px;
      }
    }
  }
}
.achieve-tit {
  font-size: 18px;
  position: relative;
  padding-left: 15px;
}
.achieve-tip {
  background: #fffaf0;
  border-radius: 4px;
  padding: 10px 15px;
  line-height: 30px;
  color: #ffa914;
  font-size: 16px;
  display: flex;
  .icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  .c {
    font-size: 16px;
    line-height: 30px;
  }
}
.achieve-tip.red {
  background: #ffecec;
  color: #ff5151;
}
.achieve-tit:before {
  display: block;
  width: 4px;
  height: 20px;
  background: #3273f6;
  border-radius: 2px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -10px;
  content: "";
}
.achieve-btn {
  width: 100px;
  height: 36px;
  border-radius: 4px;
  padding: 0px;
  border: 1px solid #3273f6;
  color: #3273f6;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
}
.achieve-btn.blue {
  background: #3273f6;
  color: #fff;
}
.achieve-two {
  background: #fff;
  padding: 20px;
  
  .two-item {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid #ebebeb;
    align-items: center;
    .two-l {
      display: flex;
      .img {
        width: 44px;
        height: 44px;
        border-radius: 100%;
      }
      .two-txt {
        margin-left: 15px;
        .t {
          font-size: 16px;
          display: flex;
        }
        .c {
          font-size: 14px;
          color: #666;
          margin: 10px 0px;
          line-height: 1.5;
        }
        .color_red {
          color: #ff2121;
        }
        .color_blue {
          color: #3273f6;
        }
      }
    }
    .two-r {
      .r-hd {
        display: flex;
        align-items: center;
        .see-a {
          display: flex;
          align-items: center;
          font-size: 14px;
          cursor: pointer;
          .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
      .r-bd {
        display: flex;
        align-items: center;
        margin-top: 12px;
        margin-left: 96px;
        .name {
          font-size: 14px;
          color: #666;
        }
        .gress {
          width: 120px;
          height: 8px;
          background: #ebebeb;
          border-radius: 4px;
          position: relative;
          overflow: hidden;
        }
        .gress-s {
          background: #3273f6;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 8px;
        }
      }
    }
  }
  .two-item:nth-last-child(1) {
    border: none;
  }
}
</style>
